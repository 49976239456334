@import 'main';
h3 {
  text-align: left;
  font-size: 1.5em;
  margin: 0;
  padding: 40px 0px @pxOrange;
  font-family: @isapFontName;
}

h2 {
  text-align: left;
  font-size: 1.5em;
  padding: 0;
  margin: 0px 0px @pxBlue;

  font-family: @isapFontName;
  font-weight: 300;
}
