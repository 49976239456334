@import 'main';

.values {
  font-size: 1em;
  color: var(--fontColor);
  &--item {
    margin-top: 15px;
    .name {
      margin: 0;
      font-weight: bold;
    }
    .value {
      font-weight: 300;
      margin: 0;
    }
  }
}

.interactive {
  cursor: pointer;
  &.btn-link {
    padding: 0;
  }
}

.tabs-secondary {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;

  &__item {
    padding: 0;
    margin-right: 15px;

    &-link {
      text-decoration: none;
      color: var(--fontColor);
      opacity: 0.6;
      font-size: 17px;

      padding: @pxBlue @pxGreen @pxBlue 0;
      &.disabled {
        pointer-events: none;
        cursor: default;
      }

      .index {
        padding-right: 3px;
      }

      &.active {
        opacity: 1;
        font-weight: bold;
      }
    }
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.no-items {
  opacity: 0.3;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  padding: 20vh 0;
  font-weight: bold;
}

li.marked-list {
  list-style: none;
  &::before {
    content: '\2022';
    color: var(--primaryColor);
    display: inline-block;
    font-size: 40px;
    vertical-align: middle;
    line-height: 20px;
  }
}

.reflect-y {
  transform: scaleX(-1);
}

.confirm-end-interview-dialog {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  align-items: center;

  &__img {
    height: 80px;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 500;
  }

  button {
    width: 150px;
  }

  .btn-secondary {
    margin-bottom: 8px;
  }
}

.chip-item {
  padding: 8px;
  background: var(--primaryLightColor);
  display: flex;
  width: fit-content;
  color: var(--fontColor);
  border-radius: 16px;
  align-items: center;
  gap: 8px;

  i {
    cursor: pointer;
  }
}
