@isapWhite: #ffffff;
@isapBlack: #333;
// @isapGrey: #CBCBCB;
// @isapBackgroundGrey: #f1f1f1;
// @isapThemeColor: #d9f2e9;
// @isapThemeSecondaryColor: #d96757;
// @isapThemeDarkColor: #62B08E;
// @isapThemeHighlightColor: #dcf7e8;
// @isapBackgroundColor: @isapWhite;
// @isapFontColor: @isapBlack;
// @isapBlue: #2A73A5;
// @isapWarning: #c6a74a;

@isapFontName: 'Roboto';
@isapFontSize: 16px;

body {
  font-family: @isapFontName;
}

@import './elements/input.less';
@import './elements/button.less';
@import './elements/header.less';
@import './elements/statuses.less';
@import './elements/tooltip.less';
@import './elements/common.less';
@import './normalise.less';
@import '../Roboto/roboto.less';


button,
input,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @isapFontName;
}
