@import 'main';

.btn {
  cursor: pointer;
  border: none;
  box-shadow: none;
  width: auto;
  min-width: auto;
  padding: @pxBlue;
  transition: filter 0.25s ease-in-out;
  border-radius: 4px;
  font-size: @isapFontSize;
  transition: all 0.2s ease-out;
  outline: none;
  font-family: @isapFontName;
  background-color: var(--primaryLightColor);
  color: var(--fontColor);
  &-sm{
    padding: @pxGreen;
  }
  &-primary {
    color: #fff;
    background-color: var(--primaryColor);
    font-weight: 300;
  }
  &-secondary {
    color: #fff;
    font-weight: 300;
    background-color: var(--secondaryColor);
  }
  &-cancel {
    color: #fff;
    font-weight: 300;
    background-color: var(--primaryLightColor);
  }
  &-edit {
    color: #fff;
    padding: @pxGreen @pxBlue;
    font-weight: 300;
    background-color: #bfbfbf;
  }
  &-delete {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    background: none;
  }
  &-danger {
    color: #fff;
    background-color: var(--dangerColor);
  }
  &-warning {
    color: #fff;
    background-color: var(--accentColor);
  }
  &-link {
    color: var(--dangerColor);
    text-decoration-color: var(--dangerColor);
    background-color: transparent;
    text-decoration: underline;
    outline: none;
    font-weight: 300;
    &:enabled,
    &:active,
    &:hover,
    &:visited {
      border: none;
      background-color: transparent;
      outline: none;
    }
  }

  &:hover {
    filter: brightness(90%);
  }
  &:disabled,
  &.disabled {
    opacity: 0.3;
    filter: grayscale(100%);
    pointer-events: none;
  }
  i.icon + span,
  span + i.icon {
    display: inline-block;
    margin-left: @pxGreen;
  }
}
