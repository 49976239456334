/* You can add global styles to this file, and also import other style files */
@import 'assets/fontawesome-free-5.15.4-web/css/all.css';
//TODO: En WCS ListVy

//TODO: WCS Knappar

//TODO: WCS Drop down

//TODO: WCS Form
@import 'main';

body {
  margin: 0;
  padding: 0;
  background-color: var(--backgroundColor);
  color: var(--fontColor);
}

.shape {
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  background-color: @isapWhite;
  color: @isapBlack;
  border: 1px solid @isapBlack;
  display: inline-block;
  margin-right: @pxBlue;
  &.sm{
    height: 12px;
    width: 12px;
    min-width: 12px;
    min-height: 12px;
  }
  &.m{
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  &.circle {
    border-radius: 50%;
  }
}
.no-pointer-events {
  pointer-events: none;
}
.active {
  color: @isapWhite;
  background: var(--secondaryColor);
  border-color: var(--secondaryColor);
}

.success {
  background: var(--primaryColor);
  color: @isapWhite;
  border-color: var(--primaryColor);
}



.warning {
  background: var(--accentColor);
  color: @isapWhite;
  border-color: var(--accentColor);
}

.danger {
  background: var(--dangerColor);
  color: @isapWhite;
  border-color: var(--dangerColor);
}

.p-tree-filter-icon{
  margin-right: 14px;
}

.cursor {
  cursor: pointer;
}

.hoverable_table {
    th, tr, .p-paginator {
      background: transparent !important;
    }

    thead > tr > th:hover,
    tbody > tr:hover{
      background: var(--secondaryBackgroundColor) !important;
      cursor: pointer;
    }

    &--contrast {
      thead>tr>th:hover,
        tbody>tr:hover {
          background: var(--backgroundColor) !important;
          cursor: pointer;
        }
    }
}