@media screen and (max-width: 1024px) {
  .location-tabs {
    display: none !important;
  }
  .p-inputtext{
    min-width: 0;
  }
  .nav-sidebar .p-tree{
    background: var(--primaryLightColor);
  }
  .nav-sidebar .p-sidebar .menu{
        padding: 13px 15px;
  }
  .p-sidebar{
    padding: 0;
  }
  .nav-sidebar .p-tree .p-treenode-children{
    background: var(--backgroundColor);
  }
  .app-workspaces-dropdown .dropdown{
    margin: 10px 0 !important;
    width: 100% !important;
  }
  .app-workspaces-dropdown .dropdown .dropdown--selected{
    width: 100% !important;
  }
  .app-workspaces-dropdown .dropdown .dropdown--selected .dropdown--selected-value{
    width: 100%;
  }
  .app-workspaces-dropdown .dropdown .dropdown--options{
    width: 100%;
    z-index: 3;
  }
  .nav-sidebar .logo{
    display: flex;
    justify-content: center;
  }
  .nav-sidebar .logo img{
    width: 20vh;
  }
  .nav-sidebar .p-sidebar-content{
    height: 100%;
  }
  .nav-sidebar .p-tree-wrapper p{
    color: var(--fontColor);
    text-decoration: none;
    font-weight: 400;
    padding: 13px 15px !important;
    width: 100%;
  }
  .nav-sidebar .p-tree-wrapper{
    border-radius: 0%;
    border-color: var(--fontColor);
  }
  .nav-sidebar .p-tree-wrapper .p-treenode-selectable{
    padding: 0 !important;
  }
  .nav-sidebar .p-tree .tree-picker-tree__node{
    display: flex;
  }
  .nav-sidebar .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label{
    width: 100%;
  }
}

::ng-deep {
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    color: var(--fontColor)!important;
  }
}

@media screen and (min-width: 1024px) {
  .nav-sidebar {
    display: none !important;
  }
}