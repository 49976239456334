@import '../main.less';
@import 'indentation-variables';

input.isap-input,
textarea.isap-input {
  color: var(--fontColor);
  background-color: var(--backgroundColor);
  border: 1px solid var(--fontColor);
  padding: 8px @pxBlue;
  border-radius: 0;
  display: block;
  font-size: 16px;
  width: 100%;
  font-family: @isapFontName;
  font-weight: 300;
  outline: none;
  transition: box-shadow 0.15s ease-in-out;

  &:focus {
    box-shadow: 0 0 @pxBlue 0px rgba(var(--primaryColor), 70%);
    border-color: var(--primaryColor);
  }

  &.ng-invalid:not(.ng-pristine) {
    border-color: #d96456;
    box-shadow: 0 0 @pxBlue 0px rgba(#d96456, 70%);
  }
}

.isap-search {
  height: 40px;
}
label {
  width: 100%;
  font-weight: bold;
  position: relative;

  i.icon {
    position: absolute;
    right: 0;
    top: 50%;
    font-size: 1.5em;
    font-weight: bold;
    transform: translateY(-50%);

    &-password {
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.2s ease-in;
      width: @pxOrange;
      text-align: center;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.isap-label {
    color: var(--fontColor);
    margin: 0 0 @pxGreen;
    display: block;
    font-size: @isapFontSize;

    &.required::after {
      color: #d9675a;
    }

    &.non-required::after {
      color: #64b08f;
    }
  }
}

.validation-flag {
  font-size: 16px;
  padding: 0 2px;

  &--optional {
    color: #64b08f;
  }

  &--required {
    color: #d9675a;
  }
}

.isap-field:not(:first-child) {
  margin-top: 20px;
}

::-webkit-input-placeholder {
  color: #b7b7b7;
}

:-moz-placeholder {
  color: #b7b7b7;
}

::-moz-placeholder {
  color: #b7b7b7;
}

:-ms-input-placeholder {
  color: #b7b7b7;
}
