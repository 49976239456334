@import 'main';
@import 'media-query-variables';
.status {
  display: block;
  position: relative;
  padding: 0px 8px;
  line-height: 1.41;
  margin: 0;
  .circle {
    position: absolute;
    top: 45%;
    left: -10px;
    transform: translateY(-50%);
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid @isapBlack;
  }

  &-default:before {
    background-color: #fff;
    border: 1px solid @isapBlack;
  }
  &-blue:before {
    background-color: #2b73a5;
  }
  &-green:before {
    background-color: #62b08f;
  }

  @media screen and (max-width: @wide){
    .name{
      margin-left: @pxBlue;
    }
    .circle{
      left: 0;
    }
  }
}
